import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let OurSuccessStoriesStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "100px auto",
    "@media (max-width:1240px)": {
      gap: "20px",
    },
    "@media (max-width:768px)": {
      flexDirection: "column",
      alignItems: "center",
      margin: "50px auto",
    },
  },
  leftContainer: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width:768px)": {
      width: "300px",
      //   border: "1px solid #3C5D94",
    },
  },
  heading: {
    color: "#3C5D94",
    fontSize: "50px",
    marginBottom: "40px",
    fontWeight: "500",

    "@media (max-width:1800px)": {
      fontSize: "40px",
    },
    "@media (max-width:1240px)": {
      fontSize: "32px",
    },
    "@media (max-width:1024px)": {
      fontSize: "24px",
      marginBottom: "20px",
    },
    "@media (max-width:768px)": {
      fontSize: "24px",
      textAlign: "center",
    },
  },
  points: {
    display: "flex",
    flexDirection: "column",
    gap: "27px",
    "& img": {
      "@media (max-width:768px)": {
        width: "15px",
      },
    },
    "@media (max-width:1800px)": {
      gap: "20px",
    },
    "@media (max-width:1240px)": {
      gap: "15px",
    },
    "@media (max-width:768px)": {
      width: "max-content",
      justifyContent: "center",
      //   border: "1px solid #3C5D94",
    },
  },
  point: {
    fontSize: "24px",
    fontWeight: "300",
    display: "flex",
    alignItems: "center",
    gap: "8px",

    "@media (max-width:1800px)": {
      fontSize: "20px",
    },
    "@media (max-width:1240px)": {
      fontSize: "16px",
    },
    "@media (max-width:1024px)": {
      fontSize: "14px",
    },
  },
  rightContainer: {
    width: "40%",
    "@media (max-width:1800px)": {
      width: "50%",
    },
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  carouselImage: {
    width: "700px",
    "@media (max-width:1800px)": {
      width: "600px",
    },
    "@media (max-width:1240px)": {
      width: "450px",
    },
    "@media (max-width:1024px)": {
      width: "350px",
    },
    "@media (max-width:768px)": {
      width: "300px",
    },
  },
};

export default OurSuccessStoriesStyles;

import React, { Component } from "react";

import BrochureForm from "./BrochureForm/BrochureForm";
import { Card, CardActionArea, Grid } from "@material-ui/core";
import ResourceCard from "../ResourceCard/ResourceCard";
import ResourcePageBrochuresConfig from "./ResourcesPageBrochuresConfig";
import ResourcesPageBrochuresSectionStyles from "./ResourcesPageBrochuresSectionStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import RoutePath from "../../../lib/RoutePath";
import "./ResourcesPageBrochuresSection.css";

let styles = ResourcesPageBrochuresSectionStyles;
const ITMSbrochure = {
  name: "Awiros AI-based ITMS",
  type: "link", // youtube/link
  // videoID: 'f0O8gQgaJq4',
  // link: 'http://google.com',
  link: RoutePath.AI_ITMS_Path,
  thumbnail: "./../../..//assets/images/resource/document-thumbnails/itms.png",
};
class ResourcesPageBrochuresSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brochureList: ResourcePageBrochuresConfig,
      brochureFormModalOpenFlag: false,
      selectedBrochureFormData: undefined,
    };
  }

  handleBrochureFormModalOpen = async () => {
    this.setState({ brochureFormModalOpenFlag: true });
  };

  handleBrochureFormModalClose = async () => {
    this.setState({
      brochureFormModalOpenFlag: false,
      selectedBrochureFormData: undefined,
    });
  };

  setSelectedBrochureData = (selectedBrochureFormData) => {
    this.setState(
      { selectedBrochureFormData },
      this.handleBrochureFormModalOpen
    );
  };

  render() {
    let { classes } = this.props;
    let { brochureList, brochureFormModalOpenFlag, selectedBrochureFormData } =
      this.state;
    return (
      <div id="awi_product_brochures" className={classes.root}>
        {brochureList.length ? (
          <>
            <div className={"h2heading"}>Product Brochures</div>
            <Grid container direction="row" spacing={3}>
              {brochureList.map((brochureData, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <ResourceCard
                      cardData={brochureData}
                      setSelectedBrochureData={this.setSelectedBrochureData}
                    />
                  </Grid>
                );
              })}

              <Grid key="itmsbrochure" item xs={12} sm={6} md={4} lg={3}>
                <div className={"root"}>
                  <Card>
                    <CardActionArea
                      className={"card"}
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href = ITMSbrochure.link; // Make sure this points to the correct file path
                        link.download =
                          "Awiros - Video AI Solutions - Intelligent Mobility.pdf"; // Set the name of the downloaded file
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                      onDrag={this.dragStarted}
                    >
                      <img
                        src={ITMSbrochure.thumbnail}
                        alt="thumbnail"
                        className={"thumbnailImage"}
                      />
                      <div className={"videoTitle"}>{ITMSbrochure.name}</div>
                    </CardActionArea>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </>
        ) : null}

        <BrochureForm
          open={brochureFormModalOpenFlag}
          onClose={this.handleBrochureFormModalClose}
          brochureFormData={selectedBrochureFormData}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ResourcesPageBrochuresSection);

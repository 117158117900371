import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let IndustriesPageFourStyles = {
  rootContainer: {
    color: "#606069",
  },

  top: {
    position: "fixed",
    right: "0",
    bottom: "0",
  },
};

export default IndustriesPageFourStyles;

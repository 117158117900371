import { createMuiTheme } from "@material-ui/core/styles";
import StyleConfig from "../lib/StyleConfig";

// Create the theme
const primaryTheme = createMuiTheme({
  palette: {
    primary: {
      main: StyleConfig.mainThemeColor, // Use your main theme color
    },
  },
});

export default primaryTheme;

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let BannerStyles = {
  bannerContainer: {
    aspectRatio: "1920/1160",
    marginTop: "1.5%",
    "@media (max-width:768)": {
      marginTop: "30px",
    },
  },
  heading: {
    // marginTop:'6%',
    fontSize: "66px",
    fontWeight: "900",
    textAlign: "center",
    padding: "2% 5% 0 5%",

    // marginBottom:'8%',
    marginBottom: "3%",
    "@media (max-width:1440px)": {
      fontSize: "36px",
      marginBottom: "5%",
    },
    "@media (max-width:1024px)": {
      fontSize: "24px",
    },
    "@media (max-width:768px)": {
      fontSize: "24px",
      marginBottom: "30px",
      marginTop: "30px",
    },
    "@media (max-width:540px)": {
      fontSize: "24px",
    },
  },
  metric: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",

    // border:'1px solid black'
    "@media (max-width:768px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  eachMetric: {
    height: "9%",
    width: "20%",
    color: "#A19B9B",
    marginBottom: "4%",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:1440px)": {
      marginBottom: "5%",
    },
    "@media (max-width:768px)": {
      // flexDirection:'row',
      // alignItems:'center',
      // justifyContent:'space-between',
      width: "70%",
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width:540px)": {
      width: "80%",
    },
  },
  count: {
    fontSize: "50px",
    fontWeight: "900",
    textAlign: "center",
    color: "#3C5D94",

    "@media (max-width:1440px)": {
      fontSize: "36px",
    },
    "@media (max-width:1024px)": {
      fontSize: "22px",
    },
    "@media (max-width:768px)": {
      textAlign: "start",
      fontSize: "18px",
    },
    "@media (max-width:540px)": {
      fontSize: "18px",
    },
  },
  countCategory: {
    textAlign: "center",
    fontSize: "28px",
    color: "black",

    "@media (max-width:1440px)": {
      fontSize: "24px",
    },
    "@media (max-width:1024px)": {
      fontSize: "18px",
    },
    "@media (max-width:768px)": {
      textAlign: "start",
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      // border:"1px solid black",
      maxHeight: "30px",
    },
    "@media (max-width:540px)": {
      fontSize: "16px",
    },
  },
  desktopInfo: {
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "2%",
    padding: "0 10%",
    // fontWeight: 500,
    "@media (max-width:1440px)": {
      fontSize: "20px",
    },
    "@media (max-width:1080px)": {
      fontSize: "16px",
    },
    "@media (max-width:1080px)": {
      fontSize: "20px",
    },
    "@media (max-width:1080px)": {
      display: "none",
    },
  },
  mobileInfo: {
    fontSize: "16px",
    textAlign: "center",
    marginBottom: "3%",
    padding: "0 10%",
    // fontWeight: 500,
    margin: "2% auto 15% auto",
    "@media (max-width:540px)": {
      fontSize: "16px",
    },
    "@media (max-width:768px)": {
      marginBottom: "30px",
      fontSize: "14px",
    },

    "@media (min-width:1080px)": {
      display: "none",
    },
  },
  trustedBy: {
    fontSize: "36px",
    color: "#A19B9B",
    textAlign: "center",
    "@media (max-width:1024px)": {
      fontSize: "16px",
    },
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "5%",
    paddingRight: "5%",
    justifyContent: "space-around",
    "@media (max-width:768px)": {
      flexWrap: "wrap",
      marginBottom: "30px",
    },
  },
  logo: {
    width: "10%",
    objectFit: "contain",
    "@media (max-width:768px)": {
      width: "20%",
      margin: "auto 5% ",
    },
  },
  trustedByImage: {
    textAlign: "center",
  },

  appSectionRelated: {
    paddingTop: "2%",
    // backgroundColor: "rgba(217, 217, 217, 0.2)",
  },
  appStackButton: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "4%",
    marginTop: "3%",
    fontSize: "20px",
    "@media (max-width:768px)": {
      marginBottom: "30px",
    },
  },
  appSectionSubHeading: {
    fontSize: "24px",
  },
  heroButton: {
    display: "flex",
    width: "max-content",
    padding: "16px 28px",
    // background: "linear-gradient(90.63deg, #F1A760 5.27%, #EF4F51 92.53%);",
    backgroundColor: "#3C5D94",
    color: "#fff",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    gap: "16px",
    // margin: "0 auto",
    textAlign: "center",
    textDecoration: "none",
    marginBottom: "2%",
    fontWeight: "1000",
    "@media (max-width:1440px)": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px",
      fontSize: 12,
      gap: "8px",
    },
    "@media (max-width:540px)": {
      padding: "4px 6px",
      fontSize: 10,
    },
  },
  heroSectionUseCase: {
    padding: "0 148px",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.17,
    paddingBottom: 38,
    "& h1": {
      fontSize: 55,
      fontWeight: 600,
    },
    [theme.breakpoints.down("xl")]: {
      "& h1": {
        fontSize: 56,
      },
    },
    "@media (max-width:1440px)": {
      "& h1": {
        fontSize: 36,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 60px",
      "& h1": {
        fontSize: 24,
      },
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
};

export default BannerStyles;

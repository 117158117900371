import RoutePath from "../../../lib/RoutePath";

let ResourcePageBrochuresConfig = [
  /*{
      name: 'Awiros OS Datasheet',
      type: 'link', // youtube/link
      // videoID: 'f0O8gQgaJq4',
      // link: 'http://google.com',
      link: RoutePath.awirosOS_DataSheetPath,
      thumbnail: './../../..//assets/images/resource/document-thumbnails/os-datasheet.png',
   },*/

  {
    name: "Offerings on the Awiros AppStack",
    type: "link", // youtube/link
    // videoID: 'f0O8gQgaJq4',
    // link: 'http://google.com',
    link: RoutePath.appstackOfferingsPath,
    thumbnail:
      "./../../..//assets/images/resource/document-thumbnails/offerings.png",
  },

  {
    name: "Awiros ANPR",
    type: "link", // youtube/link
    // videoID: 'f0O8gQgaJq4',
    // link: 'http://google.com',
    link: RoutePath.ANPR_SpecDocPath,
    thumbnail:
      "./../../..//assets/images/resource/document-thumbnails/anpr.png",
  },

  {
    name: "Awiros Facial Recognition",
    type: "link", // youtube/link
    // videoID: 'f0O8gQgaJq4',
    // link: 'http://google.com',
    link: RoutePath.FRS_SpecDocPath,
    thumbnail: "./../../..//assets/images/resource/document-thumbnails/frs.png",
  },
];

export default ResourcePageBrochuresConfig;

import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import FooterStyles from "./FooterStyles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import RoutePath from "../../lib/RoutePath";

let styles = FooterStyles;

class Footer extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction={"row"} justify={"center"}>
          <Grid item xs={12} md={2} className={classes.imageContainer}>
            <img
              className={classes.logoImage}
              src={"/assets/images/logo.svg"}
              alt="awiros logo"
            />
          </Grid>

          <Grid item xs={12} md={1} />

          <Grid item xs={6} sm={6} md={2} className={classes.gridItem}>
            <p className={classes.headingText}>Company</p>
            {/* <a
              href={RoutePath.careers}
              className={classes.link}
            >
              Careers
            </a> */}
            <Link to={RoutePath.aboutUs} className={classes.link}>
              About Us
            </Link>
            <Link to={RoutePath.contactUs} className={classes.link}>
              Contact Us
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={2} className={classes.gridItem}>
            <p className={classes.headingText}>Technology</p>
            <Link to={RoutePath.awirosOS} className={classes.link}>
              Awiros OS
            </Link>
            <Link to={RoutePath.developers} className={classes.link}>
              Developers
            </Link>
            <Link to={RoutePath.appStack} className={classes.link}>
              AppStack
            </Link>
          </Grid>
          {/*<Grid item xs={12} sm={6} md={2}>*/}
          {/*   <p className={classes.headingText}>Appliances</p>*/}
          {/*   <Link to={RoutePath.productDetailed(RoutePath.pulse)} className={classes.link}>Awiros Pulse</Link>*/}
          {/*   <Link to={RoutePath.productDetailed(RoutePath.onePlus)} className={classes.link}>Awiros One+</Link>*/}
          {/*   <Link to={RoutePath.productDetailed(RoutePath.enterprise)} className={classes.link}>Awiros Enterprise</Link>*/}
          {/*</Grid>*/}
          <Grid item xs={4} sm={2} md={1}>
            <p className={`${classes.headingText} ${classes.more}`}>More</p>
            <div className={classes.socialLinkContainer}>
              <a
                href={RoutePath.youTubePath}
                target="_blank"
                className={classes.socialLink}
              >
                <i className="fab fa-youtube" />
              </a>
              <a
                href={RoutePath.linkedInPath}
                target="_blank"
                className={classes.socialLink}
              >
                <i className="fab fa-linkedin-in" />
              </a>
              <a
                href={RoutePath.twitterPath}
                target="_blank"
                className={classes.socialLink}
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                href={RoutePath.facebookPath}
                target="_blank"
                className={classes.socialLink}
              >
                <i className="fab fa-facebook-f" />
              </a>
            </div>
          </Grid>
          {/*<Grid item xs={8} sm={4}/>*/}
        </Grid>
        <div className={classes.copyrightTextContainer}>
          <p className={classes.copyrightText}>
            © 2020 All Rights Reserved.{" "}
            <a
              className={classes.copyrightLinks}
              target="_blank"
              href={RoutePath.privacyPolicy}
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              className={classes.copyrightLinks}
              target="_blank"
              href={RoutePath.termsOfUse}
            >
              Terms of Use
            </a>
            .&nbsp;
            <Link className={classes.copyrightLinks} to={RoutePath.credits}>
              Website Graphics Credits
            </Link>
          </p>
          <p className={classes.copyrightText}>
            Awiros name and logo are registered trademarks of Awidit Systems
            Pvt. Ltd.
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);

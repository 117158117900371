import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let SecondBannerStyles = {
  banner: {
    width: "max-content",
    margin: "120px auto",
    "@media (max-width: 768px)": {
      margin: "90px auto",
    },
  },
  heading: {
    fontSize: "80px",
    fontWeight: "600",
    color: "white",
    marginBottom: "118px",
    "@media (max-width: 1440px)": {
      fontSize: "56px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "36px",
      marginBottom: "56px",
    },
    "@media (max-width: 768px)": {
      fontSize: "24px",
      marginBottom: "56px",
      textAlign: "center",
    },
  },
  firstHeading: {
    display: "flex",
    gap: "8px",
    "@media (max-width: 1440px)": {
      gap: "5px",
    },
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "3px",
    },
  },
  compVis: {
    color: "#2C2C2C",
    "-webkit-text-stroke": "3px #FFCC00",
    "@media (max-width: 768px)": {
      "-webkit-text-stroke": "1px #FFCC00",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "100px",
    "@media (max-width: 768px)": {
      gap: "70px",
    },
  },
  card: {
    display: "flex",
    gap: "80px",
    color: "white",
    "@media (max-width: 768px)": {
      gap: "20px",
    },
  },
  cardImage: {
    objectFit: "contain",
    "@media (max-width: 1440px)": {
      width: "400px",
    },
    "@media (max-width: 1024px)": {
      width: "300px",
    },
    "@media (max-width: 768px)": {
      width: "200px",
    },
    "@media (max-width: 600px)": {
      width: "150px",
    },
  },
  cardDetails: {
    width: "750px",
    display: "flex",
    justifyContent: "center",

    gap: "20px",
    flexDirection: "column",
    "@media (max-width: 1440px)": {
      width: "500px",
    },
    "@media (max-width: 1024px)": {
      width: "300px",
    },
    "@media (max-width: 768px)": {
      width: "220px",
    },
    "@media (max-width: 600px)": {
      width: "180px",
      gap: "10px",
    },
  },
  cardDetailsHeading: {
    fontSize: "80px",
    fontWeight: "500",
    lineHeight: "1",
    "@media (max-width: 1440px)": {
      fontSize: "44px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "28px",
    },
    "@media (max-width: 768px)": {
      fontSize: "24px",
      lineHeight: "1.3",
    },
    "@media (max-width: 600px)": {
      fontSize: "18px",
    },
  },
  cardDetailsDescription: {
    fontSize: "32px",
    fontWeight: "400",
    "@media (max-width: 1440px)": {
      fontSize: "28px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "24px",
    },
    "@media (max-width: 768px)": {
      fontSize: "18px",
    },
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
};

export default SecondBannerStyles;

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let AwirosOSIntegratingCompStyles = {
  headings: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 300,
    color: "#3b566e",
    // lineHeight: 1.17,
    // margin: 0,
    marginBottom: 80,
    // [theme.breakpoints.down("md")]: {
    //   fontSize: 36,
    //   marginBottom: 80,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: 24,
    //   marginBottom: 40,
    //   marginTop: "30px",
    //   padding: "0px 15px 0px 15px",
    // },
  },
  integrationsContainer: {
    padding: "80px 190px",
    textAlign: "center",
    paddingBottom: 18,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 60,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },
  operationSectionContainer: {
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
    marginBottom: 50,
    "& button": {
      height: "max-content",
      zIndex: 1,
      background: "#fff",
      border: "10px solid #3b566e",
      borderRadius: 8,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      margin: 0,
      background: "#fff",
      color: "#3b566e",
      borderRadius: 8,
      padding: "16px 0 ",
      width: "220px",
      fontWeight: 300,
      fontSize: 24,
      lineHeight: 1.17,
      "&:disabled": {
        background: "#3b566e",
        color: "#fff",
        borderColor: "#fff",
      },
    },
    [theme.breakpoints.down("md")]: {
      "& button": { padding: "8px 16px", borderWidth: 5, width: "180px" },
      marginBottom: 60,
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        padding: "6px 8px",
        fontSize: 12,
        borderWidth: 4,
        width: "100px",
      },
      marginBottom: 60,
    },
    "@media (max-width:420px)": {
      "& button": {
        padding: "6px 0px",
        fontSize: 16,
        borderWidth: 4,
        width: "100px",
      },
      marginBottom: 30,
    },
  },
  divider: {
    position: "absolute",
    width: "100%",
    height: 2,
    zIndex: 0,
    top: "40%",
  },
  grid: {
    background: "rgba(217, 217, 217, 0.2)",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    aspectRatio: "500/260",
    "@media (max-width:768px)": {
      marginBottom: "40px",
    },
  },
  circleAndText: {
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  here: {
    width: "40px",
    height: "40px",
    border: "0.5px dashed rgba(59, 86, 110,0.4)",
    borderRadius: "50%",
    position: "relative",
    "@media (min-width:0px)": {
      "&:hover": {
        "& $text": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
  },
  cursor: {
    position: "absolute",
    top: "60%",
  },

  baseCircle: {
    position: "absolute",
    top: "0",
    left: "5%",
    bottom: "0px",
    right: "0px",
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    backgroundColor: "#3b566e",
    margin: "auto",
  },

  pulse: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    border: "1px solid #3b566e",
    backgroundColor: "#3b566e",
    animation: `$pulsating 2000ms ease-in`,
    animationIterationCount: "infinite",
  },
  "@keyframes pulsating": {
    "0%": {
      transform: "scale(0.3)",
      opacity: "0.2",
    },
    "50%": {
      opacity: "0.4",
    },
    "100%": {
      transform: "scale(1)",
      opacity: "0.2",
    },
  },
  "@keyframes opac": {
    "0%": {
      opacity: "0.0",
    },
    "50%": {
      opacity: "0.2",
    },
    "100%": {
      opacity: "0.0",
    },
  },
  text: {
    width: "320px",
    height: "100px",
    position: "absolute",
    top: "-10px",
    left: "-10px",
    display: "none",
    borderRadius: "10px",
    backgroundColor: "white",
    padding: "10px",
    fontSize: "16px",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 10px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    zIndex: "100",
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "white",
    "@media (max-width:1440px)": {
      width: "220px",
      height: "100px",
      fontSize: "14px",
    },
  },
  description: {
    textAlign: "start",
    color: "#3b566e",
    fontSize: "18px",
    fontWeight: "300",
    marginTop: "60px",
  },
  para: {},
  dotContent: {
    height: "200px",
  },
};

export default AwirosOSIntegratingCompStyles;

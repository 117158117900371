class RoutePath {
  // static addUserPath = '/user/add';
  // static editUserPath = (id = ':id') => `/user/edit/${id}`;

  static homePath = "/";
  static awirosOS = "/awiros-os";
  static developers = "/spira";
  static appStack = "/appstack";
  static resourcesPage = "/resources";
  static credits = "/credits";

  // intel
  static intelInnovation = "/intel-innovation-22";

  // case study
  static caseStudy = "/case-study";

  // static companyMatching = '/company';
  static contactUs = "/contact";
  static aboutUs = "/about";
  static support = "/support";

  static products = "/appliances";
  static productDetailed = (page = ":page") => `/appliances/${page}`;
  static platform = "platform";
  static pulse = "pulse";
  static onePlus = "edge-plus";
  static enterprise = "v-stream";
  static gStream = "g-stream";

  static industries = (industry = ":industry") => `/industries/${industry}`;
  static industriesMatching = "/smartCities";
  static appathon = "/appathon";
  static appathonDashboard = "/appathonDashboard";
  static industriesPageTwo = "/warehouses";
  static industriesPageThree = "/manufacturing";
  static industriesPageFour = "/smartCampuses";
  static industriesPageFive = "/trafficManagement";
  static try = "/try";
  static smartCities = "smart-cities";
  static safetyAndSecurity = "safety-and-security";
  static smartHomes = "smart-homes";
  static retail = "retail";

  static privacyPolicy = "/docs/awidit_privacy_policy.pdf";
  static termsOfUse = "/docs/Terms_of_use.pdf";
  static policy = "/policy";
  static legalDisclaimer = "/legalDisclaimer";
  static eula = "/eula";
  static appathon = "/appathon";
  static appathonPrivacyPolicy = "/docs/appathon_privacy_policy.pdf";
  static appathonTermsOfUse = "/docs/appathon_terms_of_use.pdf";
  static appathonSchedule = "assets/appathon/awiorsIntel_appathon.pdf";

  // static careers = "https://angel.co/awidit/jobs";
  static careers = "/careers";

  static blog = "https://medium.com/@awiros";
  static dashboardPath = "https://console.awiros.com/";

  static facebookPath = "https://www.facebook.com/awirosonline/";
  static twitterPath = "https://twitter.com/awirosweb";
  static linkedInPath = "https://www.linkedin.com/company/awiros";
  static youTubePath = "https://www.youtube.com/c/Awiros-OS";
  static spiraGithubLink = "https://github.com/Awiros/awiros-dev";

  static awirosOS_DataSheetPath =
    "https://www.awiros.com/files/awiros-os-datasheet-17-03-2020.pdf";
  static vStreamDataSheetPath =
    "https://www.awiros.com/files/awiros-v-stream-v1.1.pdf";
  static gStreamDataSheetPath =
    "https://www.awiros.com/files/awiros-v-sigma-v1.1.pdf";
  static edgePlusDataSheetPath =
    "https://awiros.com/files/awirosedgeplus7v7.21.5.pdf";
  static AI_ITMS_Path =
    // "https://www.awiros.com/files/awiros-ai-based-itms-v2-17-08-2020.pdf";
    "./../../..//assets/product-brochures/Awiros - Video AI Solutions - Intelligent Mobility.pdf";
  static ANPR_SpecDocPath =
    "https://www.awiros.com/files/Awiros-ANPR-spec-doc-V3-17-08-2020.pdf";
  static FRS_SpecDocPath =
    "https://www.awiros.com/files/awiros-frs-v2.3-28-12-2020.pdf";
  static appstackOfferingsPath =
    "https://www.awiros.com/files/Awiros_Offerings_V1.1.pdf";

  static navigateTo(props, path) {
    props.history.push(path);
  }
}

export default RoutePath;

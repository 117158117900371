import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CarouselComponentWithDots from "../../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";
import withWidth from "@material-ui/core/withWidth";
import Section1Styles from "./Section1Styles";
import Section1Card from "./Section1Card/Section1Card";
import RoutePath from "../../../../lib/RoutePath";

import IndustriesBanner from "../../IndustriesBanner";
import AppathonBanner from "../../AppathonBanner";

let styles = Section1Styles;

class Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstImageLoadedFlag: false,
    };

    this.contentArray = [
      <AppathonBanner></AppathonBanner>,
      <IndustriesBanner></IndustriesBanner>,
      <Section1Card
        history={this.props.history}
        headingText={"The World's first Video AI OS"}
        textContent={
          "From development to delivery to scaling, Awiros orchestrates the entire lifecycle of any Video AI application with an unmatched level of flexibility"
        }
        logo={"assets/images/resource/os2.gif"}
        buttonName={
          <>
            Learn about the Awiros OS&nbsp;
            <i className="fas fa-arrow-right" />
          </>
        }
        buttonLink={RoutePath.awirosOS}
        callImageLoadedCallback={true}
        imageLoadedCallback={this.handleFirstImageLoaded}
      />,
      <Section1Card
        history={this.props.history}
        headingText={'"Use-cases" are "Apps" on the Awiros AppStack'}
        textContent={
          "The  world's first Video AI app marketplace which has an app for all kinds of Video AI use cases."
        }
        logo={"assets/images/resource/appstack-banner.png"}
        buttonName={
          <>
            Go to the Awiros AppStack&nbsp;
            <i className="fas fa-arrow-right" />
          </>
        }
        buttonLink={RoutePath.appStack}
      />,
      <Section1Card
        history={this.props.history}
        headingText={"Developers Welcome!"}
        textContent={
          "Productize your Video AI algorithms and take your solutions to a world-wide market 10x faster!"
        }
        logo={"assets/images/resource/spira-banner.png"}
        buttonName={
          <>
            Learn about Awiros Spira&nbsp;
            <i className="fas fa-arrow-right" />
          </>
        }
        buttonLink={RoutePath.developers}
      />,
    ];
  }

  handleFirstImageLoaded = () => {
    this.setState({ firstImageLoadedFlag: true });
    // this.state.firstImageLoadedFlag
  };
  render() {
    let { classes, width } = this.props;
    return (
      <div className={classes.root}>
        <CarouselComponentWithDots
          contentArray={this.contentArray}
          aspectRatioWidth={1200}
          aspectRatioHeight={width === "xs" ? 400 : 600}
          visibleSlides={1}
          interval={7000}
          isIntrinsicHeight={true}
          isPlaying={this.state.firstImageLoadedFlag}
        />
        {/* this.state.firstImageLoadedFlag */}
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(Section1));

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let IndustriesPageStyles = {
  rootContainer: {
    color: "#606069",
  },
  cardComponent: {
    margin: 0,
  },
  carousel: {
    position: "relative",
    paddingBottom: 50,
  },
  carouselDetailsConatiner: {
    color: "#fff",
    padding: 83,
    width: "100%",
    height: "auto",
    display: "flex",

    boxSizing: "border-box",
    "@media (max-width:768px)": {
      backgroundBlendMode: "darken",
    },

    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 40,
      margin: "0",
    },
    paddingBottom: 16,
  },
  carouselDetails: {
    maxWidth: "80%",

    [theme.breakpoints.down("lg")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  carouselMetrics: {
    display: "flex",
    gap: "44px",
    [theme.breakpoints.down("md")]: {
      gap: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  bannerTitle: {
    fontSize: 56,
    lineHeight: 1.17,
    fontWeight: 1200,
    fontFamily: "Roboto",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
    "@media (max-width:500px)": {
      fontSize: 24,
    },
  },
  bannerSubtitle: {
    fontSize: 24,
    // fontWeight: 400,
    lineHeight: 1.17,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      display: "none",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    "@media (max-width:500px)": {
      fontSize: 16,
    },
  },
  contactUSButton: {
    borderRadius: "30px",
  },
  metrics: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("md")]: {
      gap: "8px",
      "& img": {
        width: 30,
        height: 30,
      },
      flexDirection: "column",
      padding: "3% 0% 3% 0%",
    },
  },
  metricData: {
    display: "flex",
    flexDirection: "column",
    "&:last-child": {
      flexShrink: 0,
      width: "max-content",
      "@media (max-width:1440px)": {
        textAlign: "center",
      },
    },
  },
  contact: {
    fontSize: 30,
    margin: "60px 0",
    border: "none",
    lineHeight: 1.17,
    borderRadius: "10px",
    padding: 0,
    "& a": {
      textDecoration: "none",
      padding: "16px 24px",
      display: "flex",
      alignItems: "center",
      color: "#000",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      margin: 0,
      marginTop: 16,
      "& a": {
        padding: "8px 12px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  contactContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  carretRight: {
    width: 40,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: 28,
      height: 28,
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
  },
  bartnote: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carouselVideo: {
    maxWidth: "40%",
    width: "100%",
    height: "100%",
    marginTop: "auto",
    position: "relative",
    "&>video": {
      zIndex: 10,
      position: "absolute",
      bottom: 0,
      transform: "translateY(50px)",
      width: "80%",
      aspectRatio: "4/3",
      objectFit: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
      margin: "0 auto",
      "&>video": {
        position: "relative",
        transform: "translate(50px,100px)",
        width: "80%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "80%",
    },
    "@media (max-width:500px)": {
      maxWidth: "100%",
    },
  },
  // carouselVideo: {
  //   // maxWidth: "40%",
  //   // width: "100%",
  //   // height: "100%",
  //   // marginTop: "auto",
  //   position: "relative",
  //   border: "1px solid red",
  //   // "&>video": {
  //   //   zIndex: 10,
  //   //   position: "absolute",
  //   //   bottom: 0,
  //   //   transform: "translateY(50px)",
  //   //   width: "80%",
  //   // },
  // },
  // videoContainer: {
  //   position: "absolute",
  //   bottom: "-50px",
  //   width: "200px",
  //   height: "300px",
  //   border: "1px solid yellow",
  //   "&>video": {
  //     objectFit: "cover",
  //   },
  // },
  heroSection: {
    "& :not(:last-child)": {
      marginBottom: 60,
    },
    "@media (max-width:500px)": {
      "& :not(:last-child)": {
        marginBottom: 30,
      },
    },

    // border:"1px solid black",
    "@media (max-width:768px)": {
      paddingTop: "30px",
      paddingBottom: "0px",
    },
  },
  smartCities: {
    fontSize: 96,
    lineHeight: 1.17,
    fontWeight: 900,
    fontFamily: "Roboto",
    margin: "0 26px",
    [theme.breakpoints.down("xl")]: {
      fontSize: 56,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
      margin: "0 16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      margin: "0 8px",
    },
  },
  heroSectionTitle: {
    fontSize: "56px",
    lineHeight: 1.17,
    fontWeight: 900,
    paddingLeft: "2%",
    padddingRight: "2%",
    // fontFamily: "Roboto",
    textAlign: "center",
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: '56px',
    // },
    "@media (max-width:1440px)": {
      fontSize: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      paddingLeft: "32px",
      paddingRight: "32px",
    },
  },
  heroSectionSummary: {
    fontSize: 24,
    // fontWeight: 500,
    padding: "0 148px",
    lineHeight: 1.17,
    textAlign: "center",
    "@media (max-width:1440px)": {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 1,
      padding: "0 60px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      padding: "0 32px",
    },
    "@media (max-width:768px)": {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },
  heroButton: {
    display: "flex",
    width: "max-content",
    padding: "16px 28px",
    // background: "linear-gradient(90.63deg, #F1A760 5.27%, #EF4F51 92.53%);",
    // background: `linear-gradient(90.51deg, #373B44 -3.74%, #4286F4 103.23%)}`,
    backgroundColor: "#3C5D94",
    alignItems: "center",
    color: "#fff",
    borderRadius: "10px",
    justifyContent: "center",
    gap: "16px",
    margin: "0 auto",
    textDecoration: "none",
    fontSize: "20px",
    fontWeight: "1000",
    "@media (max-width:1440px)": {
      padding: "10px 14px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px 8px 16px",
      fontSize: 12,
      gap: "8px",
      marginBottom: "300px",
    },
  },
  heroSectionUseCase: {
    padding: "0 148px",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.17,
    marginBottom: 18,
    // border:'1px solid black',
    "& h1": {
      fontSize: 55,
      fontWeight: 600,
    },
    [theme.breakpoints.down("xl")]: {
      "& h1": {
        fontSize: 56,
      },
    },
    "@media (max-width:1440px)": {
      "& h1": {
        fontSize: 36,
      },
    },

    [theme.breakpoints.down("sm")]: {
      padding: "0 60px",
      marginBottom: 0,
      "& h1": {
        fontSize: 24,
      },
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
  },
  appSection: {
    padding: "44px 154px",
    background: "rgba(217, 217, 217, 0.2)",
    [theme.breakpoints.down("xl")]: {
      padding: "36px 128px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 20px",
    },
  },
  appSectionHeading: {
    fontSize: "40px",
    "@media (max-width:1280px)": {
      fontSize: "28px",
    },
  },
  appSearch: {
    width: "60%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    border: "2px solid #606069",
    borderRadius: 8,
    padding: 16,
    gap: "40px",
    "& input": {
      width: "100%",
      fontSize: 20,
      lineHeight: 1.17,
      border: 0,
      background: "transparent",
      "&:focus": {
        outline: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      width: "100%",
      gap: "16px",
      "& img": {
        width: 20,
        height: 20,
      },
      "& input": {
        fontSize: 14,
      },
    },
  },
  appsCarousel: {
    position: "relative",
  },
  appsCarouselButtons: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    cursor: "pointer",
  },
  appsContainer: {
    display: "flex",
    scrollBehavior: "smooth",
    overflowX: "auto",
    gap: "16px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  appCard: {
    backgroundSize: "cover",
    height: 375,
    width: 300,
    flexShrink: 0,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  flip: {
    animation: `$rotatingAni 10000ms ease-in`,
  },
  "@keyframes rotatingAni": {
    "0%": {
      transform: "rotateY(0deg)",
    },
    "100%": {
      transform: "rotateY(90deg)",
    },
  },
  mainAppCard: {
    position: "relative",
    border: "1px solid black",
  },
  descriptionCard: {
    position: "absolute",
    top: "0",
    left: "0",
    color: "black",
    width: "100%",
    height: "100%",
    padding: "5%",
    //  border:'1px solid red',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    animation: `$delayedRotatingAni 10000ms ease-in`,
  },
  delayedFlip: {
    animation: `$rotatingAni 10000ms ease-in`,
    animationDelay: "1000ms",
  },
  "@keyframes delayedRotatingAni": {
    "0%": {
      transform: "rotateY(90deg)",
    },
    "10%": {
      transform: "rotateY(0deg)",
    },
    "100%": {
      transform: "rotateY(0deg)",
    },
  },
  appCardDetails: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    gap: "8px",
    padding: 4,
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  appIcons: {
    width: 32,
    height: 32,
  },
  emptyState: {
    textAlign: "center",
    fontSize: 24,
    marginTop: 48,
    fontWeight: 600,
  },
  integrationsContainer: {
    padding: "180px 190px",
    textAlign: "center",
    paddingBottom: 18,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 60,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },
  operationSectionContainer: {
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
    marginBottom: 100,
    "& button": {
      height: "max-content",
      zIndex: 1,
      padding: 8,
      background: "#fff",
      border: "13px solid #F1965D",
      borderRadius: 8,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      margin: 0,
      background: "#fff",
      color: "#F1965D",
      borderRadius: 8,
      padding: "16px 24px",
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.17,
      "&:disabled": {
        background: "#F1965D",
        color: "#fff",
        borderColor: "#fff",
      },
    },
    [theme.breakpoints.down("md")]: {
      "& button": { padding: "8px 16px", borderWidth: 5 },
      marginBottom: 60,
    },
    [theme.breakpoints.down("sm")]: {
      "& button": { padding: "6px 8px", fontSize: 16, borderWidth: 4 },
      marginBottom: 30,
    },
  },
  divider: {
    position: "absolute",
    width: "100%",
    height: 2,
    zIndex: 0,
    top: "40%",
  },
  grid: {
    background: "rgba(217, 217, 217, 0.2)",
  },
  betterSection: {
    padding: "100px 150px",
    marginBottom: 120,
    textAlign: "center",
    paddingBottom: 0,
    "& h2": {
      fontSize: 55,
      margin: 0,
    },
    "& p": {
      margin: 0,
      fontSize: 20,
    },
    [theme.breakpoints.down("md")]: {
      padding: "60px 100px",
      paddingBottom: 0,
      "& h2": {
        fontSize: 36,
        margin: 0,
      },
      "& p": {
        margin: 0,
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "100px 150px",
      paddingBottom: 0,
      "& h2": {
        fontSize: 24,
        margin: 0,
      },
      "& p": {
        margin: 0,
        fontSize: 14,
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      padding: "32px 16px",
    },
  },
  imageContainer: {
    padding: "0 24.6%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  imageSection: {
    aspectRatio: "970/800",
    position: "relative",
    maxWidth: 970,
  },
  tiles: {
    "& img": {
      width: 52,
      height: 52,
      [theme.breakpoints.down("lg")]: {
        width: 30,
        height: 30,
      },
    },
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    width: 148,
    height: 148,
    [theme.breakpoints.down("lg")]: {
      width: 120,
      height: 120,
      fontSize: 12,
    },
    [theme.breakpoints.down("md")]: {
      width: 96,
      height: 96,
    },
    padding: 16,
    textAlign: "center",
    border: "1px solid rgba(240, 74, 81, 1)",
  },
  scaleCircle: {
    transform: "scale(1.20)",
  },
  container: {
    position: "absolute",
    width: 250,
    top: 0,
    [theme.breakpoints.down("md")]: {
      width: 200,
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  containerLeft: {
    left: 0,
    transform: "translate(-100%,0)",
  },
  containerRight: {
    right: 0,
    transform: "translate(100%,0)",
  },
  diverse: {
    position: "absolute",
    bottom: "10%",
    transform: "translate(-50%,0)",
  },
  dynamicDeployment: {
    position: "absolute",
    bottom: "50%",
    transform: "translate(-50%,25%)",
  },
  hardware: {
    position: "absolute",
    top: "10%",
    left: "10%",
    transform: "translate(-10%,-35%)",
  },
  scale: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  support: {
    position: "absolute",
    top: "10%",
    right: "10%",
    transform: "translate(-10%,-45%)",
  },
  realTime: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translate(50%,-75%)",
  },
  easyIntegrate: {
    position: "absolute",
    bottom: "10%",
    right: 0,
    transform: "translate(50%,0%)",
  },
  deployments: {
    padding: "180px 124px",
    "@media (max-width:1440px)": {
      padding: "50px 124px",
    },
    // [theme.breakpoints.down("lg")]: {
    //   padding: "180px 60px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   padding: "100px 30px",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   padding: 0,
    //   paddingTop: 32,
    // },
    paddingBottom: 0,
  },

  imageContainerMobile: {
    paddingBottom: 30,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  imageCarousel: {
    "& > div": {
      width: "max-content",
      margin: "0 auto",
    },
  },

  deploymentImage: {
    width: 300,
    height: 300,
    flexShrink: 0,
    [theme.breakpoints.down("lg")]: {
      width: 240,
      height: 240,
    },
  },
  deploymentBannerContainer: {
    display: "flex",
    padding: 1,
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "max-content",
      margin: "0 auto",
    },
  },
  deploymentMetrics: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(4,1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.up("xl")]: {
      width: "max-content",
    },
  },
  deploymentCells: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    maxWidth: 300,
    "& div": {
      flexShrink: 0,
      width: "50%",
      maxWidth: 150,
      textAlign: "center",
      aspectRatio: "1/1",
    },
  },
  deploymentMetricCells: {
    flexBasis: "50%",
    backgroundImage:
      "linear-gradient(to bottom left,rgba(240, 74, 81, 1),rgba(242, 167, 96, 1))",
  },
  iconImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
    "& img": {
      width: 64,
      height: 64,
    },
    [theme.breakpoints.down("md")]: {
      "& img": {
        width: 50,
        height: 50,
      },
    },
  },
  gradientText: {
    "-webkit-text-fill-color": "transparent",
    background:
      "linear-gradient(to left ,rgba(240, 74, 81, 1),rgba(242, 167, 96, 1))",
    "-webkit-background-clip": "text",
  },
  metricsData: {
    fontSize: 30,
    fontWeight: 700,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#fff",
    "& span": {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      "& span": {
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      "& span": {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      "& span": {
        fontSize: 14,
      },
    },
  },
  gutterSpace: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  backdrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#fff",
    top: 0,
    right: 0,
  },
  partnerContainer: {
    padding: "180px 65px",
    [theme.breakpoints.down("md")]: {
      padding: "100px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px 16px",
    },
  },
  headings: {
    textAlign: "center",
    fontSize: 55,
    fontWeight: 600,
    lineHeight: 1.17,
    color: "#919192",
    margin: 0,
    marginBottom: 126,
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      marginBottom: 80,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginBottom: 30,
    },
  },
  partners: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  partnerImage: {
    flexBasis: "20%",
    maxWidth: 400,
    height: 265,
    padding: 16,
    flexShrink: 0,
    "& img": {
      objectFit: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "33%",
    },
  },
  button: {
    border: 0,
    background: "transparent",
    padding: 0,
  },
  carouselLeft: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  carouselRight: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(50%,-50%)",
  },
};

export default IndustriesPageStyles;

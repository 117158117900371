import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let MainBannerStyles = {
  MainBanner: {
    aspectRatio: "1920/885",
    display: "flex",
    position: "relative",
    flexDirection: "row",

    "@media (max-width:768px)": {
      display: "none",
    },
  },
  smoothAni: {
    "@media (min-width:769px)": {
      animation: "$fadeIn 1s linear",
    },
    "@media (max-width:768px)": {
      animation: "$scaleUp 1s linear",
    },
  },
  "@keyframes scaleUp": {
    "0%": {
      transform: "scale(0.98)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0.8,
      transform: "scale(0.98)",
    },
    "50%": {
      opacity: 0.9,
      transform: "scale(0.99)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  leftSide: {
    width: "40%",
    height: "100%",
    color: "black",
    backgroundColor: "#F7F7F7",
  },
  increaseLeft: {
    width: "40%",
  },
  decreaseRight: {
    width: "60%",
  },
  rightSide: {
    width: "60%",
    height: "100%",
    backgroundBlendMode: "darken",
    position: "relative",
  },
  leftHeading: {
    marginTop: "30%",
    height: "40%",
    position: "relative",
    fontSize: "70px",
    marginRight: "50px",
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "700",
    "@media (max-width:1510px)": {
      fontSize: "44px",
    },
    "@media (max-width:1440px)": {
      fontSize: "40px",
    },
    "@media (max-width:1080px)": {
      fontSize: "32px",
      marginRight: "20px",
      height: "50%",
    },
  },
  rightHeading: {
    marginTop: "17%",
    height: "40%",
    position: "relative",
    fontSize: "50px",
    marginLeft: "50px",
    color: "white",
    fontWeight: "500",
    "@media (max-width:1510px)": {
      fontSize: "40px",
    },
    "@media (max-width:1440px)": {
      fontSize: "36px",
    },
    "@media (max-width:1080px)": {
      fontSize: "28px",
      marginLeft: "20px",
      height: "50%",
    },
  },
  firstLine: {
    position: "absolute",
    top: "5%",
    opacity: "1",
  },
  secondLine: {
    position: "absolute",
    top: "30%",
    opacity: "1",
  },
  thirdLine: {
    position: "absolute",
    top: "55%",
    opacity: "1",
  },
  bringLine1Attop: {
    animation: `$bringLine1AtTop 1000ms ease-in`,
  },
  bringLine2Attop: {
    animation: `$bringLine2AtTop 1000ms ease-in`,
  },
  bringLine3Attop: {
    animation: `$bringLine3AtTop 2000ms ease-in`,
  },
  bringLine4Attop: {
    animation: `$bringLine4AtTop 2000ms ease-in`,
  },
  bringLine5Attop: {
    animation: `$bringLine5AtTop 2000ms ease-in`,
  },

  "@keyframes bringLine1AtTop": {
    "0%": {
      top: "100%",
      opacity: "0",
    },
    "100%": {
      top: "5%",
      opacity: "1",
    },
  },
  "@keyframes bringLine2AtTop": {
    "0%": {
      top: "120%",
      opacity: "0",
    },
    // "50%": {
    //     top:'100%',
    //     opacity:'0'
    //     },
    "100%": {
      top: "30%",
      opacity: "1",
    },
  },
  "@keyframes bringLine3AtTop": {
    "0%": {
      top: "100%",
      opacity: "0",
    },
    "50%": {
      top: "100%",
      opacity: "0",
    },
    // "67%": {
    //     top:'100%',
    //     opacity:'0'
    //     },
    "100%": {
      top: "5%",
      opacity: "1",
    },
  },
  "@keyframes bringLine4AtTop": {
    "0%": {
      top: "120%",
      opacity: "0",
    },
    "50%": {
      top: "120%",
      opacity: "0",
    },
    // "75%": {
    //     top:'100%',
    //     opacity:'0'
    //     },
    "100%": {
      top: "30%",
      opacity: "1",
    },
  },
  "@keyframes bringLine5AtTop": {
    "0%": {
      top: "140%",
      opacity: "0",
    },
    "50%": {
      top: "140%",
      opacity: "0",
    },
    // "80%": {
    //     top:'100%',
    //     opacity:'0'
    //     },
    "100%": {
      top: "55%",
      opacity: "1",
    },
  },
  note: {
    display: "flex",
    flexDirection: "row",
    color: "white",
    alignItems: "center",
    position: "absolute",
    bottom: "10%",
    marginTop: "10%",
    width: "100%",
    // border:'1px solid black',
    "@media (max-width:1080px)": {
      marginTop: "5%",
    },

    justifyContent: "center",
  },
  noteText: {
    fontSize: "20px",
    marginRight: "30px",
    "@media (max-width:1080px)": {
      marginRight: "20px",
      fontSize: "16px",
    },
  },
  demoButton: {
    // fontSize:"24px",
    // borderRadius:"30px",
    // padding:'10px 20px 10px 20px',
    // // borderColor:'white',
    // fontWeight:'600',
    // border:"none",
    // margin:"auto",
    // cursor:'pointer',
    // '@media (max-width:1080px)':{
    //     padding:'5px 10px 5px 10px',
    //     fontSize:"16px",
    // },
    borderRadius: "30px",
  },
  contact: {
    fontSize: 30,
    margin: "60px 0",
    lineHeight: 1.17,
    borderRadius: "10px",
    // background: "linear-gradient(90.51deg, #373B44 -3.74%, #4286F4 103.23%)",
    "@media (min-width:768px)": {
      backgroundColor: "#3C5D94",
    },

    padding: 0,
    color: "white",
    border: "none",
    "& a": {
      textDecoration: "none",
      padding: "16px 24px",
      display: "flex",
      alignItems: "center",
      color: "black",
      "@media (min-width:768px)": {
        color: "white",
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      margin: 0,
      marginTop: 16,
      "& a": {
        padding: "8px 12px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  contactContainer: {
    // border: "1px solid black",
    display: "flex",
    justifyContent: "end",
    color: "white",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    //   display: "flex",
    //   justifyContent: "center",
    //   "& a": {
    //     padding: "8px 12px",
    //   },
    // },
    "@media (max-width:768px)": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& a": {
        padding: "8px 12px",
      },
    },
    marginRight: "50px",
    "@media (max-width:1080px)": {
      marginRight: "20px",
    },
  },
  carretRight: {
    width: 20,
    height: 20,
    fill: "white",
    marginLeft: "10px",
    // [theme.breakpoints.down("md")]: {
    //   width: 28,
    //   height: 28,
    // },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
    "@media (max-width:768px)": {
      marginLeft: "0px",
    },
  },

  mobileViewMainBanner: {
    aspectRatio: "1/1",
    position: "relative",
    backgroundBlendMode: "darken",
    "@media (min-width:525px)": {
      aspectRatio: "1/0.8",
    },
    "@media (min-width:768px)": {
      display: "none",
      marginBottom: "30px",
    },
  },
  MVHeading: {
    color: "white",
    fontSize: "56px",
    fontWeight: "600",
    marginBottom: "5%",
    paddingTop: "20%",
    paddingLeft: "5%",
    paddingRight: "5%",
    textAlign: "center",
    "@media (max-width:600px)": {
      fontSize: "36px",
      paddingTop: "15%",
    },
  },
  MVDetail: {
    color: "white",
    fontSize: "28px",
    marginTop: "5%",
    marginBottom: "10%",
    textAlign: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    "@media (max-width:600px)": {
      fontSize: "24px",
    },
  },
  MVnote: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    alignItems: "center",
    // marginTop:'5%',
    textAlign: "center",
    justifyContent: "center",
    gap: "20px",
    position: "absolute",
    bottom: "10%",
    width: "100%",
    // border:"1px solid black",
    // marginTop:'10%',
    // left:"40%",
    margin: "auto",

    // '@media (max-width:500px)':{
    //     bottom:"35%",
    //     left:"33%",
    // },
    // '@media (max-width:400px)':{
    //     bottom:"40%",
    //     left:"33%",
    // },
  },
  MVnoteText: {
    fontSize: "24px",
    "@media (max-width:420px)": {
      fontSize: "20px",
    },
  },
};

export default MainBannerStyles;

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let BannerCompStyles = {
  rootContainer: {
    color: "#606069",
  },
  cardComponent: {
    margin: 0,
  },
  carousel: {
    position: "relative",
    paddingBottom: 50,
  },
  carouselDetailsConatiner: {
    color: "#fff",
    padding: 83,
    width: "100%",
    height: "auto",
    display: "flex",
    border: "1px solid yellow",
    boxSizing: "border-box",

    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 40,
      margin: "0",
    },
    paddingBottom: 16,
  },
  carouselDetails: {
    maxWidth: "80%",

    [theme.breakpoints.down("lg")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  carouselMetrics: {
    display: "flex",
    gap: "44px",
    [theme.breakpoints.down("md")]: {
      gap: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bannerTitle: {
    fontSize: 56,
    lineHeight: 1.17,
    fontWeight: 1200,
    fontFamily: "Roboto",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
    "@media (max-width:500px)": {
      fontSize: 24,
    },
  },
  bannerSubtitle: {
    fontSize: 28,
    fontWeight: 800,
    lineHeight: 1.17,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    "@media (max-width:500px)": {
      fontSize: 16,
    },
  },
  metrics: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("md")]: {
      gap: "8px",
      "& img": {
        width: 30,
        height: 30,
      },
    },
  },
  metricData: {
    display: "flex",
    flexDirection: "column",
    "&:last-child": {
      flexShrink: 0,
      width: "max-content",
    },
  },
  contact: {
    fontSize: 30,
    margin: "60px 0",
    lineHeight: 1.17,
    padding: 0,
    "& a": {
      textDecoration: "none",
      padding: "16px 24px",
      display: "flex",
      alignItems: "center",
      color: "#000",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      margin: 0,
      marginTop: 16,
      "& a": {
        padding: "8px 12px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  contactContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  carretRight: {
    width: 40,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: 28,
      height: 28,
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
  },
  bartnote: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carouselVideo: {
    maxWidth: "40%",
    width: "100%",
    height: "100%",
    marginTop: "auto",
    position: "relative",
    "&>video": {
      zIndex: 10,
      position: "absolute",
      bottom: 0,
      transform: "translateY(50px)",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
      margin: "0 auto",
      "&>video": {
        position: "relative",
        transform: "translateY(100px)",
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "80%",
      },
      "@media (max-width:500px)": {
        maxWidth: "100%",
      },
    },
  },
};

export default BannerCompStyles;

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let CustomersAndPartnersStyles = {
  partnerContainer: {
    padding: "180px 65px",
    filter: `grayscale(1)`,

    [theme.breakpoints.down("md")]: {
      padding: "100px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px 16px",
    },
  },
  headings: {
    textAlign: "center",
    fontSize: 55,
    fontWeight: 600,
    lineHeight: 1.17,

    margin: 0,
    marginBottom: 126,
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      marginBottom: 80,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      marginBottom: 30,
    },
  },
  partners: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  partnerImage: {
    flexBasis: "18%",
    maxWidth: 400,
    height: 265,
    padding: 16,
    flexShrink: 0,
    "& img": {
      objectFit: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "33%",
    },
  },
  button: {
    border: 0,
    background: "transparent",
    padding: 0,
  },
  carouselLeft: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  carouselRight: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(50%,-50%)",
  },
};

export default CustomersAndPartnersStyles;
